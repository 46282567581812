/* eslint-disable camelcase */
import { z } from "zod";

export const ProductSchema = z.object({
  product_name: z.string(),
  unit_price: z.string(),
  product_code: z.string(),
  supplier_id: z.string(),
});

export type Product = z.infer<typeof ProductSchema>;
