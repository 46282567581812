import { QUERY_KEYS } from "@/_shared/query-keys";
import { useMutation } from "@/_shared/use-mutation";
import { InvoiceRepo } from "@/core/repo/invoice.repo";
import { LoadingOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Card, Col, Flex, Row, Spin } from "antd";
import type { RcFile, UploadFile } from "antd/es/upload";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Preview } from "./preview";
import "./styles.scss";
import { Upload } from "./upload";

type Files = Array<UploadFile<RcFile>>;
const repo = new InvoiceRepo();

export function Page() {
  const [files, setFiles] = useState<Files>();
  const [extractData, setExtractData] = useState<
    Array<{
      file: UploadFile<RcFile>;
      originFile: File;
      data: any;
    }>
  >();
  const navigate = useNavigate();

  const onChange: UploadProps<RcFile>["onChange"] = (info) => {
    setFiles(info.fileList);
  };

  const uploadFile = async (originFile: File): Promise<string | null> => {
    try {
      const { file_path } = await repo.upload(originFile);
      return file_path;
    } catch (error) {
      console.error("File upload failed:", error);
      return null;
    }
  };

  const extractFileData = async (filePath: string) => {
    try {
      return await repo.extract(filePath);
    } catch (error) {
      console.error("Data extraction failed:", error);
      throw error;
    }
  };

  const preview = async () => {
    if (!files) return;

    const updatedItems = await Promise.all(
      files.map(async (file) => {
        const originFile = file.originFileObj;
        if (!originFile) return null;

        let filePath: string | null = null;
        let extractedData: any = null;

        // Attempt to upload the file
        filePath = await uploadFile(originFile);

        // Attempt to extract data if upload succeeded
        if (filePath) {
          try {
            extractedData = await extractFileData(filePath);
          } catch {
            file.status = "error"; // Mark file status as error if extraction fails
          }
        } else {
          file.status = "error"; // Mark file status as error if upload fails
        }

        return {
          file,
          originFile,
          data: extractedData,
        };
      }),
    );

    // Filter out null items in case of skipped files
    setExtractData(
      updatedItems.filter((item) => item !== null) as Array<{
        file: UploadFile<RcFile>;
        originFile: File;
        data: any;
      }>,
    );
  };

  const mutation = useMutation({
    mutationFn: preview,
    invalidateQueryKeys: [...QUERY_KEYS.invoice.all],
  });

  return (
    <Card title={<h2>Invoice Scanner</h2>} className="invoice-extract_card">
      <div className="invoice-extract_card_body">
        <Card type="inner" title={<b>Upload</b>}>
          <div className="invoice-extract_card_upload-container">
            <Row>
              <Col span={24}>
                <Upload
                  disabled={Boolean(extractData) || mutation.isPending}
                  onChange={onChange}
                  fileList={extractData ? extractData.map((each) => each.file) : files}
                />
              </Col>
            </Row>
            <Row>
              <Col span={4} offset={8}>
                <Button
                  disabled={mutation.isPending}
                  color="default"
                  variant="outlined"
                  style={{
                    display: "flex",
                    margin: "20px auto",
                  }}
                  onClick={() => {
                    navigate("/invoice-mgmt");
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  disabled={!files || Boolean(extractData) || mutation.isPending}
                  onClick={mutation.mutate}
                  style={{
                    display: "flex",
                    margin: "20px auto",
                  }}
                >
                  Preview
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
        <Card type="inner" className="invoice-extract_card-preview" title={<b>Preview</b>}>
          {mutation.isPending && !extractData && (
            <Flex align="center" justify="center">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </Flex>
          )}
          {!mutation.isPending && extractData && (
            <div className="invoice-extract_card_preview-container">
              <Preview extractData={extractData} />
            </div>
          )}
        </Card>
      </div>
    </Card>
  );
}
