import { Form as AntdForm, Input } from "antd";
import type { Form } from "./schema";

interface Props {
  data: Form["invoices"][number];
}
export function ExtractForm(props: Props) {
  return (
    <AntdForm labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" style={{ maxWidth: 600 }}>
      <AntdForm.Item label="Invoice ID">
        <Input value={props.data.invoice_id} />
      </AntdForm.Item>
      <AntdForm.Item label="Invoice Date">
        <Input value={props.data.invoice_date} />
      </AntdForm.Item>
      <AntdForm.Item label="Supplier ID">
        <Input value={props.data.supplier_id} />
      </AntdForm.Item>
      <AntdForm.Item label="Total Amount">
        <Input value={props.data.total_amount} />
      </AntdForm.Item>
    </AntdForm>
  );
}
