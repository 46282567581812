import { z } from "zod";

export const StatusEnum = ["pending_approval", "approved"] as const;

export type Status = (typeof StatusEnum)[number];

export const ParamsSchema = z.object({
  search: z.string().optional(),
  status: z.enum(StatusEnum).optional(),
  page: z.coerce.number().optional().default(1),
});

export type Params = z.infer<typeof ParamsSchema>;
