import { type MutationFunction, useQueryClient, useMutation as useReactQueryMutation } from "@tanstack/react-query";

interface Props<TData, TVariables> {
  mutationFn: MutationFunction<TData, TVariables>;
  invalidateQueryKeys: Array<string>;
}
export function useMutation<TData, TVariables>(props: Props<TData, TVariables>) {
  const queryClient = useQueryClient();
  return useReactQueryMutation({
    mutationFn: props.mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: props.invalidateQueryKeys,
      });
    },
  });
}
