import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider, type ThemeConfig } from "antd";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { store } from "./_shared/store";
import { DeliveryNotePage } from "./delivery-note";
import { InvoiceExtractPage } from "./invoice-extract";
import { InvoiceMgmtPage } from "./invoice-mgmt";
import { Layout } from "./layout";
import { OrderPage } from "./order";
import { ReportPage } from "./report";
import { TopPage } from "./top";

const themeConfig: ThemeConfig = {};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      throwOnError: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.error("Error in QueryClient - QueryCache:", error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      console.error("Error in QueryClient - MutationCache:", error);
    },
  }),
});

const routers = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/order",
        element: <OrderPage />,
      },
      {
        path: "/delivery-note",
        element: <DeliveryNotePage />,
      },
      {
        path: "/invoice-extract",
        element: <InvoiceExtractPage />,
      },
      {
        path: "/invoice-mgmt",
        element: <InvoiceMgmtPage />,
      },
      {
        path: "/report",
        element: <ReportPage />,
      },
      {
        path: "*",
        element: <TopPage />,
      },
    ],
  },
]);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={themeConfig}>
        <Provider store={store}>
          <RouterProvider router={routers} />
        </Provider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
