import { QUERY_KEYS } from "@/_shared/query-keys";
import { useMutation } from "@/_shared/use-mutation";
import type { InvoiceExtract } from "@/core/entity/invoice-extract.entity";
import { InvoiceRepo } from "@/core/repo/invoice.repo";
import { zodResolver } from "@hookform/resolvers/zod";
import { notification, type UploadFile } from "antd";
import type { RcFile } from "antd/es/upload";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";
import { transformInvoiceExtractToFormSchema } from "./_utils/transformInvoiceExtractToFormSchema";
import { PreviewItem } from "./preview-item";
import { FormSchema } from "./schema";

interface Props {
  extractData: Array<{
    file: UploadFile<RcFile>;
    originFile: File;
    data: InvoiceExtract;
  }>;
}

const repo = new InvoiceRepo();

export function Preview(props: Props) {
  const navigate = useNavigate();
  const formMethods = useForm({
    values: {
      invoices: props.extractData.map((each) => transformInvoiceExtractToFormSchema(each.data)),
    },
    resolver: zodResolver(FormSchema),
  });

  const { fields } = useFieldArray({
    name: "invoices",
    control: formMethods.control,
  });

  const mutation = useMutation({
    mutationFn: repo.addInvoices,
    invalidateQueryKeys: [...QUERY_KEYS.invoice.all],
  });

  const onImportAll = formMethods.handleSubmit((data) => {
    mutation
      .mutateAsync(data.invoices)
      .then(() => {
        navigate("/invoice-mgmt");
        notification.success({
          message: "Import completed successfully.",
        });
      })
      .catch(() => {
        notification.error({
          message: "An error occurred.",
        });
      });
  });

  return (
    <FormProvider {...formMethods}>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        {fields.map((each, index) => {
          return (
            <PreviewItem
              key={each.id}
              file={props.extractData[index]?.originFile}
              index={index}
              onImportAll={onImportAll}
            />
          );
        })}
      </Swiper>
    </FormProvider>
  );
}
