import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload as AntdUpload } from "antd";
import type React from "react";

const { Dragger } = AntdUpload;

interface Props {
  disabled: boolean;
  fileList: UploadProps["fileList"];
  onChange: UploadProps["onChange"];
}

export const Upload: React.FC<Props> = (props) => {
  const DraggerProps: UploadProps = {
    name: "file",
    multiple: false,
    fileList: props.fileList,
    beforeUpload: () => false,
    onChange: props.onChange,
  };

  return (
    <Dragger {...DraggerProps} disabled={props.disabled}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Dragger>
  );
};
