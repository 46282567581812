import { z } from "zod";

export const itemSchema = z.object({
  Description: z.string(),
  Quantity: z.number(),
  UnitPrice: z.string(),
  ProductCode: z.string(),
  Tax: z.string(),
  Amount: z.string(),
});

export const InvoiceExtractSchema = z.object({
  VendorName: z.string(),
  VendorAddressRecipient: z.string(),
  InvoiceId: z.string(),
  InvoiceDate: z.string(),
  InvoiceTotal: z.string(),
  PurchaseOrder: z.string(),
  TotalTax: z.string(),
  VendorTaxId: z.string(),
  CustomerTaxId: z.string(),
  Items: z.array(itemSchema),
});

export type InvoiceExtract = z.infer<typeof InvoiceExtractSchema>;
