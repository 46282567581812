import axios from "axios";
import { apiKey, baseUrl, reqTimeout } from "../../constants/app";

const apiPath = "/api/v1/run";

const axiosInstance = axios.create({
  baseURL: baseUrl + apiPath,
  timeout: reqTimeout,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  },
});

export const orderPath = {
  C: "/orders?stream=false",
  R: "/list-all-orders?stream=false",
  R1: "/orderId?stream=false",
  U: "/update-orderId?stream=false",
  D: "/delete-orderId?stream=false",
};

export const productPath = {
  R: "/d4565b63-e041-4d34-8160-f8c1dda2a003?stream=false",
};

export default axiosInstance;
