/* eslint-disable camelcase */
/* eslint-disable no-duplicate-imports */
import type { ProductOrder } from "@/core/entity/product-order.entity";
import { Col, Input, Row } from "antd";
import { useMemo, type FC } from "react";

type PROPS = {
  dataProductsOrder: Array<ProductOrder>;
};

export const TotalProductOrder: FC<PROPS> = (props) => {
  const { dataProductsOrder } = props || {};

  const total = useMemo(
    () =>
      dataProductsOrder.reduce((total, i) => {
        const { quantity, unit_price } = i || {};
        return total + (Number.parseInt(quantity as string) || 0) * Number.parseFloat(unit_price || "0");
      }, 0),
    [dataProductsOrder],
  );

  return (
    <Row align={"bottom"} gutter={[30, 10]} style={{ marginBottom: 30 }}>
      <Col span={1}></Col>
      <Col span={7}></Col>
      <Col span={7}></Col>
      <Col span={9}>
        <b>Total price</b>
        <Input placeholder="" value={total.toFixed(2)} contentEditable={false} />
      </Col>
    </Row>
  );
};
