import { Card } from "antd";

export function Page() {
  return (
    <Card title="Top page">
      <p> content</p>
      <p> content</p>
      <p> content</p>
    </Card>
  );
}
