import { useQueryParamsBuilder } from "@/_shared/query-params/useQueryParamsBuilder";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Select } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Status } from "./params";
import { ParamsSchema, type Params } from "./params";
import { InvoiceTable } from "./table";
const { Search } = Input;
const prefix = <SearchOutlined />;

export function Page() {
  const navigate = useNavigate();
  const queryParamsBuilder = useQueryParamsBuilder<Params>(ParamsSchema);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(queryParamsBuilder.getCurrentParamValue("search"));

  const onSearch = () => {
    queryParamsBuilder.addParam("search", searchTerm);
    queryParamsBuilder.apply();
  };

  const onChangeStatus = (value: Status) => {
    queryParamsBuilder.addParam("status", value);
    queryParamsBuilder.apply();
  };

  const onClear = () => {
    queryParamsBuilder.removeParam("search");
    queryParamsBuilder.apply();
  };

  return (
    <Card title={<h2>Invoice Management</h2>}>
      <Row gutter={8}>
        <Col span={4}>
          <Select
            allowClear
            placeholder="Select status"
            size="large"
            value={queryParamsBuilder.getCurrentParamValue("status")}
            onChange={(value) => onChangeStatus(value)}
            style={{
              width: "100%",
              fontSize: "16px",
            }}
            options={[
              { value: "pending_approval", label: <span>Pending Approval</span> },
              { value: "approved", label: <span>Approved</span> },
            ]}
          />
        </Col>
        <Col span={8}>
          <Search
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Input search text"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            onClear={onClear}
            prefix={prefix}
          />
        </Col>
        <Col span={4} offset={8}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{
              width: "100%",
            }}
            onClick={() => {
              navigate("/invoice-extract");
            }}
          >
            New invoice
          </Button>
        </Col>
      </Row>
      <div
        style={{
          height: 10,
        }}
      ></div>
      <Row>
        <Col span={24}>
          <InvoiceTable />
        </Col>
      </Row>
    </Card>
  );
}
