/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-duplicate-imports */
/* eslint-disable camelcase */
import type { Order } from "@/core/entity/order.entity";
import type { ProductOrder } from "@/core/entity/product-order.entity";
import { OrderRepo } from "@/core/repo/order.repo";
import { ProductRepo } from "@/core/repo/product.repo";
import { ExclamationCircleFilled, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { MenuProps, TableColumnsType } from "antd";
import { Button, Card, Col, Dropdown, message, Modal, Table } from "antd";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import ModalOrder from "./components/ModalOrder";

const { confirm } = Modal;

export const Page: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState<string>("");
  const [dataProductsOrder, setDataProductsOrder] = useState<Array<ProductOrder>>([]);
  const [orderSelected, setOrderSelected] = useState<Order | null>(null);
  const queryClient = useQueryClient();

  const { isPending: isPendingProducts, data: dataProducts } = useQuery({
    queryKey: ["ProductRepo.fetchAll"],
    queryFn: () => ProductRepo.getInstance().fetchAll(),
  });

  const { isPending: isPendingOrders, data: dataOrders } = useQuery({
    queryKey: ["OrderRepo.fetchAll"],
    queryFn: () => OrderRepo.getInstance().fetchAll(),
  });

  const mutation = useMutation({
    mutationFn: OrderRepo.getInstance().fetchOne,
    onSuccess: (data) => {
      if (data[0]?.order_id) {
        setOrderId(data[0]?.order_id);
      }
      setDataProductsOrder(data);
      showModal();
    },
    onError: () => {
      message.error("Fetch error.");
    },
  });

  const mutationDelete = useMutation({
    mutationFn: OrderRepo.getInstance().delete,
    onSuccess: () => {
      message.success(`Delete order successfully.`);
      queryClient.invalidateQueries({ queryKey: ["OrderRepo.fetchAll"] });
    },
    onError: () => {
      message.error(`Delete order error.`);
    },
  });

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
    setDataProductsOrder([]);
    setOrderId("");
  }, []);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <span>Edit</span>,
      },
      {
        key: "2",
        label: <span>Delete</span>,
      },
    ],
    [],
  );

  const onConfirmDelete = useCallback(() => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete item?",
      onOk() {
        if (orderSelected?.order_id) {
          mutationDelete.mutate(orderSelected?.order_id);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }, [mutationDelete, orderSelected?.order_id]);

  const onMenuClick = useCallback(
    (item: Order) => () => {
      setOrderSelected(item);
    },
    [],
  );

  const onOptionsMenuClick: MenuProps["onClick"] = useCallback(
    ({ key }: { key: string }) => {
      if (orderSelected) {
        if (key === "1") {
          mutation.mutate(orderSelected?.order_id);
        } else if (key === "2") {
          onConfirmDelete();
        }
      }
    },
    [mutation, orderSelected, onConfirmDelete],
  );

  const columns: TableColumnsType<Order> = useMemo(
    () => [
      {
        title: "No.",
        rowScope: "row",
        render: (_, __, index) => <span>{index + 1}</span>,
      },
      {
        title: "ORDER ID",
        dataIndex: "order_id",
      },
      {
        title: "SUPPLIER",
        dataIndex: "supplier_id",
      },
      {
        title: "ORDER DATE",
        dataIndex: "order_date",
      },
      {
        title: "",
        dataIndex: "",
        render: (_, record) => (
          <Dropdown
            menu={{ items, onClick: onOptionsMenuClick }}
            placement="bottomLeft"
            arrow={{ pointAtCenter: true }}
            trigger={["click"]}
          >
            <Button onClick={onMenuClick(record)} style={{ border: "none" }}>
              <MoreOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ],
    [items, onOptionsMenuClick, onMenuClick],
  );

  const renderListOrder = useMemo(
    () => (
      <Col sm={24}>
        <Button size={"large"} type={"primary"} style={{ marginBottom: 10, float: "right" }} onClick={showModal}>
          <PlusOutlined /> New purchase order
        </Button>
        <Table<Order>
          bordered
          loading={isPendingOrders}
          columns={columns}
          dataSource={dataOrders}
          rowKey={"order_id"}
        />
      </Col>
    ),
    [showModal, isPendingOrders, columns, dataOrders],
  );

  return (
    <Col span={24}>
      <Card title={<h2>Order Management</h2>} bordered={false}>
        {renderListOrder}
      </Card>
      <ModalOrder
        isPendingProducts={isPendingProducts}
        dataProducts={dataProducts || []}
        orderId={orderId}
        dataProductsOrder={dataProductsOrder}
        setDataProductsOrder={setDataProductsOrder}
        isModalOpen={isModalOpen}
        onClose={hideModal}
      />
    </Col>
  );
};
