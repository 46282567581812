import type { MenuProps } from "antd";
import { Layout as AntdLayout, Menu, theme } from "antd";
import type React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./styles.scss";

const { Header, Content, Footer, Sider } = AntdLayout;

const siderStyle: React.CSSProperties = {
  overflow: "auto",
  height: "100vh",
  position: "fixed",
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: "thin",
  scrollbarGutter: "stable",
  background: "#152946",
};

export const Layout: React.FC = () => {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items: MenuProps["items"] = [
    {
      key: "purchase-order",
      label: `Purchase Order`,
      onClick: () => navigate("/order"),
    },
    {
      key: "delivery-note",
      label: "Delivery Note",
      onClick: () => navigate("/delivery-note"),
    },
    {
      key: "invoice-mgmt",
      label: `Invoice Management`,
      onClick: () => navigate("/invoice-mgmt"),
    },
    {
      key: "report",
      label: `Report`,
      onClick: () => navigate("/report"),
    },
  ];

  return (
    <AntdLayout hasSider>
      <Sider style={siderStyle}>
        <div className="demo-logo-vertical" />
        <Menu
          style={{
            backgroundColor: "#152946",
            color: "#fff",
          }}
          className="sider-menu"
          mode="inline"
          defaultSelectedKeys={["4"]}
          items={items}
        />
      </Sider>
      <AntdLayout style={{ marginInlineStart: 200 }}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <h1>Invoice Order Demo</h1>
        </Header>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center" }}>Demo ©{new Date().getFullYear()} Created by BAP</Footer>
      </AntdLayout>
    </AntdLayout>
  );
};
