import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { ZodType } from "zod";
import { QueryParamsBuilder } from "./QueryParamsBuilder";

export function useQueryParamsBuilder<T = Record<string, unknown>>(schema: ZodType<Partial<object>>) {
  const location = useLocation();
  const navigate = useNavigate();

  return useMemo(() => new QueryParamsBuilder<T>(location, navigate, schema), [location, navigate, schema]);
}
