import { useQueryParamsBuilder } from "@/_shared/query-params/useQueryParamsBuilder";
import { SearchOutlined } from "@ant-design/icons";
import { Card, Col, Input, Row } from "antd";
import { useState } from "react";
import type { Params } from "./params";
import { ParamsSchema } from "./params";
import { ReportTable } from "./table";
const { Search } = Input;
const prefix = <SearchOutlined />;

export function Page() {
  const queryParamsBuilder = useQueryParamsBuilder<Params>(ParamsSchema);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(queryParamsBuilder.getCurrentParamValue("search"));

  const onSearch = () => {
    queryParamsBuilder.addParam("search", searchTerm);
    queryParamsBuilder.apply();
  };

  const onClear = () => {
    queryParamsBuilder.removeParam("search");
    queryParamsBuilder.apply();
  };

  return (
    <Card title={<h2>Report</h2>}>
      <Row>
        <Col span={8} offset={16}>
          <Search
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="input search text"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            onClear={onClear}
            prefix={prefix}
          />
        </Col>
      </Row>
      <div
        style={{
          height: 10,
        }}
      ></div>
      <Row>
        <Col span={24}>
          <ReportTable />
        </Col>
      </Row>
    </Card>
  );
}
