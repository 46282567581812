/* eslint-disable no-duplicate-imports */
/* eslint-disable camelcase */
import axiosInstance, { orderPath } from "@/core/api/api";
import { z } from "zod";
import type { FormOrder, Order } from "../entity/order.entity";
import { FormOrderSchema, OrderSchema } from "../entity/order.entity";

export const OptionalOrderSchema = OrderSchema.extend({
  order_id: z.string().optional(),
});
export type OptionalOrder = z.infer<typeof OptionalOrderSchema>;

export class OrderRepo {
  private static instance: OrderRepo;

  public static getInstance(): OrderRepo {
    if (!OrderRepo.instance) {
      OrderRepo.instance = new OrderRepo();
    }
    return OrderRepo.instance;
  }

  public async fetchAll(): Promise<Array<Order>> {
    const res = await axiosInstance.post(orderPath.R, {
      input_value: "*",
      output_type: "chat",
      input_type: "chat",
    });
    const orders = z
      .array(OptionalOrderSchema)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .parse(JSON.parse(res.data.outputs?.[0].outputs?.[0].results.message.text as string));
    return orders as Array<Order>;
  }

  public async fetchOne(id: string): Promise<Array<FormOrder>> {
    const res = await axiosInstance.post(orderPath.R1, {
      input_value: id,
      output_type: "chat",
      input_type: "chat",
    });
    const order = z
      .array(FormOrderSchema)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .parse(JSON.parse(res.data.outputs?.[0].outputs?.[0].results.message.text as string));
    return order;
  }

  public async create(payload: Array<FormOrder>): Promise<boolean> {
    const res = await axiosInstance.post(orderPath.C, {
      input_value: JSON.stringify(payload),
      output_type: "chat",
      input_type: "chat",
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const result = (res.data.outputs?.[0].outputs?.[0].results.message.text as string).includes(
      "All queries executed successfully",
    );
    return result;
  }

  public async update(payload: Array<FormOrder>): Promise<boolean> {
    const res = await axiosInstance.post(orderPath.U, {
      input_value: JSON.stringify(payload),
      output_type: "chat",
      input_type: "chat",
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const result = (res.data.outputs?.[0].outputs?.[0].results.message.text as string).includes(
      "All queries executed successfully",
    );
    return result;
  }

  public async delete(id: string): Promise<boolean> {
    const res = await axiosInstance.post(orderPath.D, {
      input_value: id,
      output_type: "chat",
      input_type: "chat",
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const result = (res.data.outputs?.[0].outputs?.[0].results.message.text as string).includes(
      "All queries executed successfully",
    );
    return result;
  }
}
