import { cloneDeep, isEqual, merge, omit } from "lodash";
import qs from "qs";
import type { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import type { ZodType } from "zod";

export class QueryParamsBuilder<T = Record<string, unknown>> {
  private readonly originalQueryParams: Partial<T>;
  private queryParams: Partial<T>;
  private readonly location: ReturnType<typeof useLocation>;
  private readonly navigate: ReturnType<typeof useNavigate>;
  private readonly schema: ZodType<Partial<object>>;

  public constructor(
    location: ReturnType<typeof useLocation>,
    navigate: ReturnType<typeof useNavigate>,
    schema: ZodType<Partial<object>>,
  ) {
    this.location = location;
    this.navigate = navigate;
    this.schema = schema;
    this.originalQueryParams = schema.parse(qs.parse(location.search, { ignoreQueryPrefix: true }));
    this.queryParams = cloneDeep(this.originalQueryParams);
  }

  public addParam<K extends keyof T>(key: K, value: T[K]): this {
    this.queryParams[key] = value;
    return this;
  }

  public addParams(params: Partial<T>): this {
    this.queryParams = merge({}, this.queryParams, params);
    return this;
  }

  public removeParam<K extends keyof T>(key: K): this {
    delete this.queryParams[key];
    return this;
  }

  public removePage(): this {
    if ("page" in this.queryParams) delete this.queryParams["page"];
    return this;
  }

  public clearParams(): this {
    this.queryParams = {};
    return this;
  }

  public getParams(): Partial<T> {
    return cloneDeep(this.queryParams);
  }

  public get isModified(): boolean {
    return !isEqual(omit(this.originalQueryParams, "page"), omit(this.queryParams, "page"));
  }

  public getCurrentParamValue<K extends keyof T>(key: K): T[K] | undefined {
    return this.queryParams[key];
  }

  public get currentParams() {
    return this.queryParams;
  }

  public get initParams() {
    return this.originalQueryParams;
  }

  // Apply changes and update the URL
  public apply(options: Pick<NavigateOptions, "replace"> = {}): void {
    if (this.isModified) {
      this.removePage(); // Forward to the first page after applying changes
    }
    const resolvedParams = this.schema.parse(this.queryParams);
    const queryString = qs.stringify(resolvedParams, { encode: true, addQueryPrefix: true });
    this.navigate(this.location.pathname + queryString, options);
  }
}
