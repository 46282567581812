import { Button, Flex } from "antd";
import { useController } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { SwiperSlide } from "swiper/react";
import { FileViewer } from "./file-viewer";
import { ExtractForm } from "./form";
import type { Form } from "./schema";

interface Props {
  file: File;
  index: number;
  onImportAll: () => void;
}
export function PreviewItem(props: Props) {
  const navigate = useNavigate();
  const { field: record } = useController<Form, `invoices.${number}`>({
    name: `invoices.${props.index}`,
  });
  return (
    <SwiperSlide>
      <div className="preview-container">
        <div className="preview_viewer-container">
          <FileViewer file={props.file} />
        </div>
        <div className="preview_form-container">
          <ExtractForm data={record.value} />
        </div>
        <Flex align="center" justify="center" className="preview_buttons" gap={16}>
          <Button
            size="middle"
            onClick={() => {
              navigate("/invoice-mgmt");
            }}
          >
            Back to invoice
          </Button>
          {/* <Button type="primary" size="middle">
            Import this one
          </Button> */}
          <Button type="primary" size="middle" onClick={props.onImportAll}>
            Import all
          </Button>
        </Flex>
      </div>
    </SwiperSlide>
  );
}
