import { QUERY_KEYS } from "@/_shared/query-keys";
import { useQueryParamsBuilder } from "@/_shared/query-params/useQueryParamsBuilder";
import type { Invoice } from "@/core/entity/invoice.entity";
import { InvoiceRepo } from "@/core/repo/invoice.repo";
import { useQuery } from "@tanstack/react-query";
import { Table, type TableProps } from "antd";
import { Action } from "./action";
import { ParamsSchema, type Params } from "./params";

type DataType = Invoice & {
  no: number;
};

const columns: TableProps<DataType>["columns"] = [
  {
    title: "No.",
    dataIndex: "no",
    rowScope: "row",
  },
  {
    title: "Invoice ID",
    dataIndex: "invoiceId",
  },
  {
    title: "Invoice Date",
    dataIndex: "invoiceDate",
  },
  {
    title: "Order ID",
    dataIndex: "orderId",
  },
  {
    title: "Order Date",
    dataIndex: "orderDate",
  },
  {
    title: "Delivery Note ID",
    dataIndex: "deliveryNoteId",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "",
    dataIndex: "action",
    fixed: "right",
    render: (_, record) => {
      return <Action record={record} />;
    },
  },
];

const repo = new InvoiceRepo();

export const InvoiceTable: React.FC = () => {
  const queryParamsBuilder = useQueryParamsBuilder<Params>(ParamsSchema);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [...QUERY_KEYS.invoice.all, "list", queryParamsBuilder.currentParams],
    queryFn: () => {
      return repo.fetchList(queryParamsBuilder.currentParams);
    },
  });

  return <Table<DataType> bordered loading={isLoading || isFetching} columns={columns} dataSource={data ?? []} />;
};
