import { PdfViewer } from "./pdf-viewer";

export function FileViewer(props: { file: File }) {
  const fileType = props.file.type;

  if (fileType === "application/pdf") {
    return <PdfViewer file={props.file} />;
  }
  return <p>Unsupported file type</p>;
}
