export const QUERY_KEYS = {
  order: {
    all: ["order"],
  },
  report: {
    all: ["report"],
  },
  invoice: {
    all: ["invoice"],
  },
} as const;
