/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable camelcase */
/* eslint-disable no-duplicate-imports */
import type { FormOrder, Order } from "@/core/entity/order.entity";
import { OrderSchema } from "@/core/entity/order.entity";
import type { ProductOrder } from "@/core/entity/product-order.entity";
import type { Product } from "@/core/entity/product.entity";
import { OrderRepo } from "@/core/repo/order.repo";
import { PlusOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Divider, Form, Input, List, message, Modal, Row } from "antd";
import type { CSSProperties, FC } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import type { TChangeProduct, TChangeProduct2 } from "./ItemProductOrder";
import { ItemProductOrder } from "./ItemProductOrder";
import { TotalProductOrder } from "./TotalProductOrder";

type Options = { value: string; label: string };

const listStyle: CSSProperties = {
  backgroundColor: " #fff",
  borderRadius: 5,
};

type PROPS = {
  isPendingProducts: boolean;
  dataProducts: Array<Product>;
  orderId: string;
  dataProductsOrder: Array<ProductOrder>;
  setDataProductsOrder: (arg: Array<ProductOrder>) => void;
  isModalOpen: boolean;
  onClose: () => void;
};

// const ProductOrderSchemaWithoutID = ProductOrderSchema.omit({ id: true });
// type ProductOrderWithoutID = z.infer<typeof ProductOrderSchemaWithoutID>;

const defaultProductOrder: ProductOrder = {
  product_code: "",
  product_name: "",
  unit_price: "",
  quantity: 0,
  order_id: "",
  supplier_id: "",
};

const ModalOrder: FC<PROPS> = (props) => {
  const { isPendingProducts, dataProducts, orderId, dataProductsOrder, setDataProductsOrder, isModalOpen, onClose } =
    props || {};
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm<Order>({
    resolver: zodResolver(OrderSchema),
    mode: "all",
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: orderId ? OrderRepo.getInstance().update : OrderRepo.getInstance().create,
    onSuccess: (result) => {
      if (result === true) {
        message.success(`${orderId ? "Update" : "Create"} order successfully.`);
        queryClient.invalidateQueries({ queryKey: ["OrderRepo.fetchAll"] });
        onClose();
      } else {
        message.error(`${orderId ? "Update" : "Create"} order error.`);
      }
    },
    onError: () => {
      message.error(`${orderId ? "Update" : "Create"} order error.`);
    },
  });

  /** product */
  useEffect(() => {
    reset({ order_id: orderId || "" });
    if (dataProductsOrder.length === 0) {
      setDataProductsOrder([defaultProductOrder]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const optionsProducts = useMemo<Array<Options>>(
    () =>
      dataProducts
        .filter((i) => !dataProductsOrder.some((j) => j?.product_code === i.product_code))
        .map((i) => ({ value: i.product_code, label: i.product_name })),
    [dataProducts, dataProductsOrder],
  );

  const onAddProduct = useCallback(() => {
    setDataProductsOrder([...dataProductsOrder, defaultProductOrder]);
  }, [dataProductsOrder, setDataProductsOrder]);

  const onChangeProduct = useCallback(
    (index: number) => (data: TChangeProduct | TChangeProduct2) => {
      const { product_code } = (data as TChangeProduct) || {};
      const { quantity, unit_price } = (data as TChangeProduct2) || {};
      const temp = [...dataProductsOrder];
      const itemP = dataProducts.find((i) => i.product_code == product_code);
      temp[index] = {
        ...temp[index],
        ...itemP,
        ...(!unit_price && !quantity
          ? { quantity: 1 }
          : { quantity: Math.max(Number.parseInt(quantity as string, 10) || 0, 1), unit_price: unit_price || "0" }),
      } as ProductOrder;
      setDataProductsOrder([...temp]);
    },
    [dataProducts, dataProductsOrder, setDataProductsOrder],
  );

  const onRemoveProduct = useCallback(
    (item: ProductOrder) => () => {
      setDataProductsOrder(dataProductsOrder.filter((i) => i.product_code != item.product_code));
    },
    [dataProductsOrder, setDataProductsOrder],
  );

  const renderListSelected = useMemo(
    () => (
      <>
        <List
          dataSource={dataProductsOrder?.length > 0 ? dataProductsOrder : [defaultProductOrder]}
          size={"large"}
          itemLayout="vertical"
          renderItem={(item, index) => (
            <ItemProductOrder
              item={item}
              index={index}
              optionsProducts={optionsProducts}
              loading={isPendingProducts}
              onChangeProduct={onChangeProduct(index)}
              onRemoveProduct={onRemoveProduct(item)}
            />
          )}
          locale={{ emptyText: "Select product to create order" }}
          style={listStyle}
        />
        <Button type={"primary"} style={{ marginTop: 10, marginLeft: 40 }} onClick={onAddProduct}>
          <PlusOutlined /> Add product
        </Button>
        <TotalProductOrder dataProductsOrder={dataProductsOrder} />
      </>
    ),
    [dataProductsOrder, onAddProduct, optionsProducts, isPendingProducts, onChangeProduct, onRemoveProduct],
  );

  /** general  */
  const onSubmit = useCallback(() => {
    const data = getValues();
    const { order_id } = data || {};
    const dataProductsOrderFinal = dataProductsOrder
      .filter((i) => Boolean(i.product_code))
      .map<FormOrder>((i) => ({
        order_id,
        unit_price: i.unit_price,
        product_name: i.product_name,
        product_code: i.product_code,
        supplier_id: i.supplier_id,
        quantity: i.quantity.toString(),
      }));
    mutation.mutate(dataProductsOrderFinal);
  }, [getValues, dataProductsOrder, mutation]);

  const renderGeneralInfo = useMemo(() => {
    return (
      <Form>
        <Row align={"bottom"} gutter={[30, 5]}>
          <Col span={1}></Col>
          <Col span={7}>
            <b>Order id</b>
            <Form.Item
              validateStatus={errors.order_id?.message ? "error" : ""}
              hasFeedback={Boolean(errors.order_id?.message)}
              help={errors.order_id?.message}
            >
              <Controller
                name={"order_id"}
                control={control}
                render={({ field }) => <Input placeholder="" {...field} disabled={Boolean(orderId)} suffix />}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }, [control, errors.order_id?.message, orderId]);

  const disabledOk = useMemo(
    () =>
      Boolean(
        mutation.isPending || !dataProductsOrder.some((i) => Boolean(i.product_code)) || errors.order_id?.message,
      ),
    [dataProductsOrder, errors.order_id?.message, mutation.isPending],
  );

  return (
    <Modal
      title={`${orderId ? "Edit" : "Create"} order`}
      open={isModalOpen}
      width={920}
      okText={orderId ? "Update" : "Create"}
      onClose={onClose}
      onCancel={onClose}
      cancelButtonProps={{ disabled: mutation.isPending }}
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{ disabled: disabledOk }}
      destroyOnClose={true}
    >
      <Divider style={{ borderColor: "#7cb305" }} orientation={"left"}>
        <b>General information</b>
      </Divider>
      {renderGeneralInfo}
      <Divider style={{ borderColor: "#7cb305" }} orientation={"left"}>
        <b>Product information</b>
      </Divider>
      {renderListSelected}
    </Modal>
  );
};

export default ModalOrder;
