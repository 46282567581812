/* eslint-disable camelcase */
import { ProductSchema, type Product } from "@/core/entity/product.entity";
import { z } from "zod";
import axiosInstance, { productPath } from "../api/api";

export class ProductRepo {
  private static instance: ProductRepo;

  public static getInstance(): ProductRepo {
    if (!ProductRepo.instance) {
      ProductRepo.instance = new ProductRepo();
    }
    return ProductRepo.instance;
  }

  public async fetchAll(): Promise<Array<Product>> {
    const res = await axiosInstance.post(productPath.R, {
      input_value: "*",
      output_type: "chat",
      input_type: "chat",
      tweaks: {
        "ParseCSVToJson-5S237": {},
        "ParseData-tSMiZ": {},
        "ChatInput-RC18Y": {},
        "ChatOutput-fDHn1": {},
        "postgresql_operations-rOb2X": {},
        "postgresql_operations-hb6E3": {},
        "postgresql_operations-dbBn6": {},
        "Prompt-D3nX6": {},
      },
    });
    const products = z
      .array(ProductSchema)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .parse(JSON.parse(res.data.outputs?.[0].outputs?.[0].messages?.[0].message as string));
    return products;
  }
}
