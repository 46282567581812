import type { Invoice } from "@/core/entity/invoice.entity";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button } from "antd";

export function Action(props: { record: Invoice }) {
  return (
    <Button type="text">
      <EllipsisOutlined />
    </Button>
  );
}
