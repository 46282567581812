/* eslint-disable camelcase */
import { z } from "zod";

export const OrderSchema = z.object({
  order_id: z.string().min(1, { message: "Required" }),
  order_date: z.string().optional(),
  supplier_id: z.string().optional(),
  created_at: z.string().optional(),
  status: z.string().optional(),
});

export type Order = z.infer<typeof OrderSchema>;

export const FormOrderSchema = z.object({
  order_id: z.string().min(1, { message: "Required" }),
  supplier_id: z.string(),
  product_code: z.string(),
  product_name: z.string(),
  quantity: z.union([z.string(), z.number()]),
  unit_price: z.string(),
});

export type FormOrder = z.infer<typeof FormOrderSchema>;
