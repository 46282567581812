import type { InvoiceExtract } from "@/core/entity/invoice-extract.entity";
import type { Form } from "../schema";

// Transformation function
export function transformInvoiceExtractToFormSchema(invoiceExtract: InvoiceExtract): Form["invoices"][number] {
  const totalAmount = Number.parseFloat(invoiceExtract.InvoiceTotal.replaceAll(/[^\d.]/g, ""));
  const subtotal = invoiceExtract.Items.reduce(
    (sum, item) => sum + Number.parseFloat(item.Amount.replaceAll(/[^\d.]/g, "")),
    0,
  );

  return {
    invoice_id: invoiceExtract.InvoiceId,
    invoice_date: invoiceExtract.InvoiceDate,
    supplier_id: invoiceExtract.VendorTaxId,
    total_amount: totalAmount,
    items: [
      {
        order_id: invoiceExtract.PurchaseOrder,
        details: invoiceExtract.Items.map((item) => ({
          product_code: item.ProductCode,
          product_name: item.Description,
          quantity: item.Quantity,
          unit_price: Number.parseFloat(item.UnitPrice.replaceAll(/[^\d.]/g, "")),
          amount: Number.parseFloat(item.Amount.replaceAll(/[^\d.]/g, "")),
        })),
        subtotal,
      },
    ],
  };
}
