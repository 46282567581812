import { QUERY_KEYS } from "@/_shared/query-keys";
import { useQueryParamsBuilder } from "@/_shared/query-params/useQueryParamsBuilder";
import type { Report } from "@/core/entity/report.entity";
import { ReportRepo } from "@/core/repo/report.repo";
import { useQuery } from "@tanstack/react-query";
import { Table, type TableProps } from "antd";
import { ParamsSchema, type Params } from "./params";

type DataType = Report & {
  no: number;
};

const columns: TableProps<DataType>["columns"] = [
  {
    title: "No.",
    dataIndex: "no",
    rowScope: "row",
  },
  {
    title: "Order Id",
    dataIndex: "orderId",
  },
  {
    title: "Product",
    dataIndex: "product",
  },
  {
    title: "Total Qty.",
    dataIndex: "totalQty",
  },
  {
    title: "Invoiced Qty.",
    dataIndex: "invoicedQty",
  },
];

const repo = new ReportRepo();

export const ReportTable: React.FC = () => {
  const queryParamsBuilder = useQueryParamsBuilder<Params>(ParamsSchema);

  const { data } = useQuery({
    queryKey: [...QUERY_KEYS.report.all, "list", queryParamsBuilder.currentParams],
    queryFn: () => {
      return repo.fetchList(queryParamsBuilder.currentParams);
    },
  });

  return <Table<DataType> bordered columns={columns} dataSource={data ?? []} />;
};
