import { faker } from "@faker-js/faker";
import type { Report } from "../entity/report.entity";

export class ReportRepo {
  public fetchList(params: Partial<{ page: number; search: string }>) {
    const data: List = Array.from({ length: 20 }).map((_, index) => {
      const item: Item = {
        no: index,
        id: faker.string.uuid(),
        orderId: "PO#1201-0001",
        product: faker.commerce.productName(),
        totalQty: faker.number.int({ min: 10, max: 1000 }),
        invoicedQty: faker.number.int({ min: 10, max: 1000 }),
      };
      return item;
    });
    return data;
  }
}

type Item = Report & {
  no: number;
};
type List = Array<Item>;
