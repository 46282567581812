/* eslint-disable camelcase */
/* eslint-disable no-duplicate-imports */
import type { ProductOrder } from "@/core/entity/product-order.entity";
import { CloseCircleFilled } from "@ant-design/icons";
import { Col, Input, Row, Select } from "antd";
import type { FC } from "react";

type Options = { value: string; label: string };

export type TChangeProduct = {
  product_code: string;
};

export type TChangeProduct2 = {
  quantity: string | number;
  unit_price: string;
};

type PropsItemProduct = {
  item: ProductOrder;
  index: number;
  optionsProducts: Array<Options>;
  loading: boolean;
  onChangeProduct: (arg: TChangeProduct | TChangeProduct2) => void;
  onRemoveProduct: () => void;
};

export const ItemProductOrder: FC<PropsItemProduct> = (props) => {
  const { item, index, optionsProducts, loading, onChangeProduct, onRemoveProduct } = props || {};
  const { product_code, product_name, quantity, unit_price } = item || {};
  const disabled = !product_code;
  return (
    <Row align={"bottom"} gutter={[30, 10]} style={{ marginBottom: 10 }}>
      <Col span={1}>
        <b>{index + 1}</b>
      </Col>
      <Col span={7}>
        <b>Product</b>
        <Select
          value={product_name}
          style={{ width: "100%" }}
          onChange={(product_code) => onChangeProduct({ product_code })}
          options={optionsProducts}
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          loading={loading}
        />
      </Col>
      <Col span={7}>
        <b>Quantity</b>
        <Input
          placeholder=""
          value={quantity || ""}
          type="number"
          disabled={disabled}
          onChange={(e) => onChangeProduct({ unit_price, quantity: Number.parseInt(e.target.value, 10) })}
        />
      </Col>
      <Col span={7}>
        <b>Price</b>
        <Input
          placeholder=""
          value={unit_price || ""}
          type="number"
          disabled={disabled}
          onChange={(e) => onChangeProduct({ quantity, unit_price: e.target.value })}
        />
      </Col>
      <Col span={1}>
        <CloseCircleFilled onClick={onRemoveProduct} />
      </Col>
    </Row>
  );
};
