/* eslint-disable camelcase */
import { z } from "zod";

const detailSchema = z.object({
  product_code: z.string(),
  product_name: z.string(),
  quantity: z.number(),
  unit_price: z.number(),
  amount: z.number(),
});

const itemSchema = z.object({
  order_id: z.string(),
  details: z.array(detailSchema),
  subtotal: z.number(),
});

const invoiceSchema = z.object({
  invoice_id: z.string(),
  invoice_date: z.string(),
  supplier_id: z.string(),
  items: z.array(itemSchema),
  total_amount: z.number(),
});

export const FormSchema = z.object({
  invoices: z.array(invoiceSchema),
});

export type Form = z.infer<typeof FormSchema>;
