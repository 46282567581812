/* eslint-disable camelcase */
import axios from "axios";
import { get } from "lodash";
import { z } from "zod";
import { InvoiceExtractSchema } from "../entity/invoice-extract.entity";
import type { Invoice } from "../entity/invoice.entity";

export class InvoiceRepo {
  public async upload(file: File) {
    const form = new FormData();
    form.append("file", file);

    const response = await axios.post(
      "https://nocode-ai.bappartners.com/api/v1/files/upload/1a982403-018f-450d-9da4-ef7c4d45e89f",
      form,
      {
        headers: {
          "x-api-key": "sk-mJjS_rQfVwv8tUQ4PUdOiMWEaQaaLdNIMtAJnci7XDw",
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return z
      .object({
        flowId: z.string(),
        file_path: z.string(),
      })
      .parse(response.data);
  }

  public async extract(filePath: string) {
    const response = await axios.post(
      "https://nocode-ai.bappartners.com/api/v1/run/1a982403-018f-450d-9da4-ef7c4d45e89f",
      {
        input_value: "message",
        output_type: "chat",
        input_type: "chat",
        tweaks: {
          "AzureDocIntel-SbywM": {
            api_key: "Czv8K7TzmQwdtxlFTRkLnPhlt8rKt7cKM8XfxpBF1TizGui1URN7JQQJ99ALACYeBjFXJ3w3AAALACOGJ9ek",
            endpoint: "https://invoice-ocr-demo.cognitiveservices.azure.com/",
            model_name: "model_invoice_demo_20241210_1",
            type: "file",
          },
          "SaveToFileComponent-fGxWB": {
            file_name: "output.csv",
          },
          "ChatOutput-Y2E8W": {
            data_template: "{text}",
            input_value: "",
            sender: "Machine",
            sender_name: "AI",
            session_id: "",
            should_store_message: true,
          },
          "ChatInput-4t9n7": {
            files: filePath,
            sender: "User",
            sender_name: "User",
            session_id: "",
            should_store_message: true,
          },
          "FileInvoiceImport-NuTfw": {
            input_value: "",
            silent_errors: false,
          },
          "ListFields-lHYWi": {
            fields: [
              "CustomerAddressRecipient",
              "CustomerId",
              "InvoiceId",
              "InvoiceDate",
              "TotalTax",
              "InvoiceTotal",
              "VendorTaxId",
              "CustomerTaxId",
              "ShippingAddress",
              "VendorName",
              "PurchaseOrder",
              "VendorAddressRecipient",
              "Serial",
              "VesselName",
              "VesselId",
              "Items",
            ],
          },
          "note-BK0pb": {},
          "note-AhhAc": {},
          "note-12QdN": {},
          "CustomComponent-H7387": {},
        },
      },
      {
        params: {
          stream: "false",
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "sk-mJjS_rQfVwv8tUQ4PUdOiMWEaQaaLdNIMtAJnci7XDw",
        },
      },
    );

    const text = get(response, "data.outputs.0.outputs.0.results.message.text");
    // First, remove the surrounding square brackets and then parse the JSON string.
    const jsonString = text?.slice(2, -2); // Removes the surrounding quotes and brackets

    // Parse the inner JSON string to convert it to an object
    const parsedObject = JSON.parse(jsonString);
    return InvoiceExtractSchema.parse(parsedObject);
  }

  public async fetchList(params: Partial<{ page: number; search: string; status: string }>) {
    const response = await axios.post(
      "https://nocode-ai.bappartners.com/api/v1/run/list-all-invoice",
      { input_value: "*", output_type: "chat", input_type: "chat" },
      {
        params: {
          stream: "false",
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "sk-mJjS_rQfVwv8tUQ4PUdOiMWEaQaaLdNIMtAJnci7XDw",
        },
      },
    );

    const message = get(response, "data.outputs.0.outputs.0.messages.0.message");

    const data: List = [...JSON.parse(message)].map((each, index) => {
      const item: Item = {
        no: index,
        id: each.invoice_id,
        orderId: each.order_ids?.join(", "),
        orderDate: each.order_date,
        invoiceId: each.invoice_id,
        invoiceDate: each.invoice_date,
        deliveryNoteId: each.delivery_note_id,
        amount: each.total_amount,
        status: each.status,
      };
      return item;
    });
    return data;
  }

  public async addInvoices(data: any) {
    const response = await axios.post(
      "https://nocode-ai.bappartners.com/api/v1/run/c7d75527-42af-4603-b548-7dd19830d04e",
      {
        input_value: JSON.stringify(data),
        output_type: "chat",
        input_type: "chat",
      },
      {
        params: {
          stream: "false",
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "sk-mJjS_rQfVwv8tUQ4PUdOiMWEaQaaLdNIMtAJnci7XDw",
        },
      },
    );
    return response;
  }
}

type Item = Invoice & {
  no: number;
};
type List = Array<Item>;
